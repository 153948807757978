import React, { Component, createRef } from 'react';
import './TranscriptionTab.scss'
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { TranscriptionList } from './TranscriptionList';
import { Participant, TextItem } from '../../types/transcription';
import {
  ArrowUp32Filled as ArrowUpIcon,
  ArrowDown32Filled as ArrowDownIcon,
  Search32Filled as SearchIcon
} from '@fluentui/react-icons';
import { Button, Checkbox, Input } from '@fluentui/react-components';


// The number of seconds that the video starts playing before the selected highlight.
const PREPLAY_INTERVAL_SECONDS = 2;

type OnExecuteSearchCallback = (query: string) => void;

type OnSetPositionRequestCallback = (position: number) => void;

type TranscriptionTabProps = {
  showItemAtOffsetSeconds: number,
  textItems: TextItem[],
  participants: Participant[],
  initialSearchQuery: string,
  isPanelOpen: boolean, // ToDo: Is this still required?
  onExecuteSearch: OnExecuteSearchCallback,
  onSetPositionRequest: OnSetPositionRequestCallback
}

type TranscriptionTabState = {
  input: string;
  searchQuery: string;
  currentHighlightIndex: number;
  followPlayer: boolean;
  highlightCount: number;
  currentHighlightCount: number;
  queryItemsIndexes: number[];
}

class TranscriptionTabInternal extends Component<TranscriptionTabProps, TranscriptionTabState> {

  transcriptionListRef: React.RefObject<TranscriptionList> = createRef();
  inputRef: React.RefObject<HTMLInputElement> = createRef();

  state = {
    input: this.props.initialSearchQuery || '',
    searchQuery: '',
    currentHighlightIndex: -1,
    followPlayer: true,
    highlightCount: 0,
    currentHighlightCount: 0,
    queryItemsIndexes: [],
  };

  componentDidMount() {
    this.executeShowItemAtOffsetSeconds(this.props.showItemAtOffsetSeconds);

    this.setState({
        highlightCount: this.getHighlightOffsets().length
    })
  }

  componentDidUpdate(prevProps: TranscriptionTabProps) {
    if (prevProps.textItems !== this.props.textItems && prevProps.textItems.length > 0) {
        // Reset the current selected highlight when the textitems have changed because a new search query was entered.
        this.setState({
            currentHighlightIndex: -1
        })
    }

    if (prevProps.showItemAtOffsetSeconds !== this.props.showItemAtOffsetSeconds) {                        
        this.executeShowItemAtOffsetSeconds(this.props.showItemAtOffsetSeconds);
    }
  }

  executeShowItemAtOffsetSeconds = (offset: number) => {
    const transcriptionList = this.transcriptionListRef.current;
    if (transcriptionList) {
      transcriptionList.selectItemAtOffset(offset);
    }
  }

  executeShowQueryItem = (index: number) => {
    const transcriptionList = this.transcriptionListRef.current;
    if (transcriptionList) {
      transcriptionList.scrollToIndex(index);
    }
  }

  getHighlightOffsets = () => {
    let offsets: number[] = [];
    for (const textItem of this.props.textItems) {
      for (const highlight of textItem.highlights) {
        offsets.push(textItem.offsetSeconds + highlight.offsetSeconds);
      }
    }

    return offsets;
  }

  trySetNewHighlightIndex = (newIndex: number) => {
    const highlightOffsets = this.getHighlightOffsets();
    if (newIndex < 0 || newIndex > highlightOffsets.length - 1) {
      return;
    }

    const correspondingOffset = highlightOffsets[newIndex];

    let newPosition = correspondingOffset - PREPLAY_INTERVAL_SECONDS;
    if (newPosition < 0) {
      newPosition = 0;
    }

    this.setState({
      currentHighlightIndex: newIndex
    });

    // Ensure the transcription shows the correct item immediately without wating for the update from the media player.        
    this.executeShowItemAtOffsetSeconds(newPosition);

    // Report the change of position so the media player can update its position.
    this.props.onSetPositionRequest(newPosition);
  }

  handleSearchQueryOnChange = (value: string) => {
    this.setState({
      input: value
    });

    setTimeout(() => console.log(this.state.input), 0)
  }

  handleSearch = () => {
    if (this.state.input.trim() === '') {
      this.setState({
        searchQuery: '',
        highlightCount: 0,
        currentHighlightCount: 0,
        queryItemsIndexes: [],
      });

      return;
    };

    this.setState({
      searchQuery: this.state.input
    });
    
    const queryIndexes = [];

    for (let i = 0; i < this.props.textItems.length; i++) {
      if (this.props.textItems[i].content.trim().toLowerCase().includes(this.state.input.trim().toLowerCase())) {
        queryIndexes.push(i);
      }
    }

    if (queryIndexes.length) {
      const transcriptionList = this.transcriptionListRef.current;

      this.setState({
        queryItemsIndexes: queryIndexes,
        highlightCount: queryIndexes.length,
      });

      transcriptionList?.scrollToIndex(queryIndexes[0]);
      this.props.onExecuteSearch(this.state.input);
    } else {
      this.setState({
        currentHighlightCount: 0,
        highlightCount: 0,
      });
    }
  }

  handlePreviousButtonClick = () => {
    this.setState(prevState => {
      const newCount = prevState.currentHighlightCount - 1;
      if (newCount < 1) return prevState;
  
      this.executeShowQueryItem(prevState.queryItemsIndexes[newCount - 1]);
      this.trySetNewHighlightIndex(this.state.currentHighlightIndex - 1)
      return {
        ...prevState,
        currentHighlightCount: newCount
      };
    });
  }
  
  handleNextButtonClick = () => {
    this.setState(prevState => {
      const newCount = prevState.currentHighlightCount + 1;
      if (newCount > prevState.highlightCount) return prevState;
  
      this.executeShowQueryItem(prevState.queryItemsIndexes[newCount - 1]);      
      this.trySetNewHighlightIndex(this.state.currentHighlightIndex + 1)
      return {
        ...prevState,
        currentHighlightCount: newCount 
      };
    });
  }

  handleFollowPlayerChecked = (isChecked: boolean) => {
    this.setState({
      followPlayer: isChecked
    });
  }

  onEnterPressSearch = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  }

  render() {
    const { followPlayer } = this.state;

    const transcriptionData = {
      textItems: this.props.textItems,
      participants: this.props.participants
    }

    return (
      <div
        className={`video-side-bar active`}
        style={{ padding: '0' }}
      >
        <div className='header-filters'>
          <div className="support-search-input width-100">
            <div className="search-title">Query</div>
            <Input
              className="transcription-query-input"
              ref={this.inputRef}
              onKeyDown={this.onEnterPressSearch}
              value={this.state.input}
              onChange={(event) => this.handleSearchQueryOnChange(event.target.value)}
            />
            <Button
              appearance="transparent"
              onClick={this.handleSearch}
              icon={<SearchIcon />}
              title="Search"
            />
          </div>
          <div className="support-arrows">
            <div className="content-checkbox">
              <Checkbox
                label="Follow player"
                defaultChecked={followPlayer}
                onChange={(options: any) => this.handleFollowPlayerChecked(options.checked)} />
            </div>
            <div className="content-arrows">
              <div className="text">
                {this.state.currentHighlightCount} / {this.state.highlightCount}
              </div>
              <Button
                appearance="transparent"
                onClick={this.handlePreviousButtonClick}
                icon={<ArrowUpIcon />}
                title="Previous"
                disabled={this.state.highlightCount === 0
                  || this.state.currentHighlightCount === 0
                  || this.state.currentHighlightCount === 1
                }
              />
              <Button
                appearance="transparent"
                onClick={this.handleNextButtonClick}
                icon={<ArrowDownIcon />}
                title="Next"
                disabled={this.state.highlightCount === 0
                  || this.state.currentHighlightCount === this.state.highlightCount
                }
              />
            </div>
          </div>
        </div>
        <div className="transcriptionContent">
          <TranscriptionList
            ref={this.transcriptionListRef}
            enableAutoScroll={this.state.followPlayer}
            data={transcriptionData}
            onSetPositionRequest={this.props.onSetPositionRequest}
            searchQuery={this.state.searchQuery}
          />
        </div>
      </div>
    );
  }
}

export const TranscriptionTab = connectTeamsTheme(TranscriptionTabInternal as any);