import React, { Component, useContext } from 'react';
import './Play.scss';
import { VideoPlayer } from './VideoPlayer';
import { AudioPlayer } from './AudioPlayer';
import { PlaySideBar } from './PlaySideBar';
import * as transcription from '../../logic/transcription';
import { durationToPlayerDisplayString } from '../../logic/timeDisplay';
import qs from 'qs';
import log from 'loglevel';
import { AudioSearchHits, Participant, TextDiarizationSegments, TextItem, Transcription } from '../../types/transcription';
import { MediaDetail } from '../../types/MediaDetail';
import { TimelineContext, TimelineEvent } from '../../context/TimelineContext';
import { createContent } from '../../logic/contentCreation';
import css from './PlayerControls.module.scss';
import { isTranscriptionField } from '../../types/Field';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { mergeClasses } from '@fluentui/react-components';
import { onDownload } from '../../logic/downloadHandler';
import {PlayHeader} from './PlayHeader';
import {  DataContext, DataContextProps } from '../../context/DataProviderContext';

// The time in seconds that an access token for a video or subtitle will be valid.
const TOKEN_VALID_TIME_SECONDS = 3600;

type PositionRequestType = {
    position: number
}

interface PlayState {
    mediaId: string;
    searchQuery: string;
    mediaInfo: MediaDetail | null;
    contentUrl: string;
    subtitleUrl?: string;
    subtitleContents?: string;
    waveforms: number[][];
    subtitleLanguageCode: string;
    contentType: string;
    positionRequest: PositionRequestType;
    participants: Participant[];
    textItems: TextItem[];
    offsetSeconds: number;
    hasError: boolean;
    errorDescription: string;
    transcription: Transcription | null,
    diarization: TextDiarizationSegments | null
    searchInFields: string[];
    sidebarWidthChangeTrigger: number;
}

interface PlayInternalProps {
    dataContext: DataContextProps;
    location: any
    //context: any
    styles: any
}

const showQueryInTranscriptionPane = (searchInFields: string[]): boolean => {
    if (!searchInFields || searchInFields.length === 0) {
        return true;
    }

    return Boolean(searchInFields.find(name => isTranscriptionField(name)));
}

class PlayInternal extends Component<PlayInternalProps, PlayState> {
    context!: React.ContextType<typeof TimelineContext>;
    
    transcription: Transcription | null = null;
    diarization: TextDiarizationSegments | null = null;
        
    constructor(props: PlayInternalProps) {        
        super(props);
        if (!props.location) {
            throw new Error("Prop 'location' not found.");
        }

        const queryParameters = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        const searchInFields =  queryParameters.searchFields ? (queryParameters.searchFields as string).split(',') : [];
        const searchQuery = showQueryInTranscriptionPane(searchInFields) ?  queryParameters.query as string : "" ;
 
        this.state = {
            mediaId: queryParameters.mediaId as string,
            searchQuery: searchQuery,
            mediaInfo: null,
            contentUrl: '',
            subtitleUrl: '',
            subtitleContents: '',
            waveforms: [],
            subtitleLanguageCode: '',
            contentType: '',
            positionRequest: { position: 0 },
            participants: [],
            textItems: [],
            offsetSeconds: 0,
            hasError: false,
            errorDescription: '',
            transcription: null,
            diarization: null,
            searchInFields: searchInFields,
            sidebarWidthChangeTrigger: 0,
        }
    }

    async componentDidMount() {        
        let mediaInfo: MediaDetail;
        try {
            if (!this.props.dataContext){
                throw new Error("No data context available");
            }

            // Retrieve the detailed information about the media using the Id.
            mediaInfo = await this.props.dataContext.getMediaInfo(this.state.mediaId);            
            this.setState({
                mediaInfo: mediaInfo               
            });            
        }
        catch (error: any) {
            log.error(`[Play, componentDidMount] Error during retrieval of mediaInfo: ${error.message}`);
            this.setState({
                hasError: true,
                errorDescription: 'Error retrieving media information'
            });

            return;
        }

        try {
            // Look-up the video or audio in the content references.
            let audioAndVideoReferences = mediaInfo.contentReferences.filter((reference: any) => reference.type === 'audio' || reference.type === 'video');
            if (audioAndVideoReferences.length === 0) {
                // Nothing playable found...
                // ToDo: Display some error instead of crashing the application.
                throw new Error(`No audio or video content reference found in media Id ${this.state.mediaId}`);
            }

            // If both audio and video is available, the video is used.
            if (audioAndVideoReferences.length > 1) {
                if (!mediaInfo.mediaTypes.includes("video") && !mediaInfo.mediaTypes.includes("screen")) {
                    // This is actually an audio only call that has a video which displays the dominant speaker.
                    const audioReferences = audioAndVideoReferences.filter((reference: any) => reference.type === 'audio');
                    audioAndVideoReferences = audioReferences;
                } else {
                    const videoReferences = audioAndVideoReferences.filter((reference: any) => reference.type === 'video');
                    if (videoReferences.length > 0) {
                        audioAndVideoReferences = videoReferences;
                    } 
                }                
            }

            const contentType = audioAndVideoReferences[0].type;
            const contentReference = audioAndVideoReferences[0].reference;
 
            // Retrieve the URL for the subtitles if available.
            let subtitleUrl: string | undefined = undefined;
            let subtitleLanguageCode = "";
            let subtitleContents: string | undefined = undefined;
            const subtitleReferences = mediaInfo.contentReferences.filter((reference: any) => reference.type === 'subtitle');
            if (subtitleReferences.length >= 1) {
                const subtitleReference = subtitleReferences[0].reference;

                var subtitleReferenceParts = subtitleReference.split(".");
                if (subtitleReferenceParts.length >= 3) {
                    subtitleLanguageCode = subtitleReferenceParts[subtitleReferenceParts.length - 2];
                }

                const subtitleContentUrl = await this.props.dataContext.getMediaContentUrl(this.state.mediaId, subtitleReference, TOKEN_VALID_TIME_SECONDS);
                subtitleUrl = subtitleContentUrl.url;

                const subtitleContentsBlob = await this.props.dataContext.getMediaContent(this.state.mediaId, subtitleReference);
                subtitleContents = await subtitleContentsBlob.text();

                this.setState({
                    subtitleUrl: subtitleUrl,
                    subtitleContents : subtitleContents
                });
            }
            
            let participants = transcription.createParticipants(mediaInfo);
            this.setState({
                participants: participants
            });

            if (mediaInfo.contentReferences.filter((reference: any) => reference.type === 'az-transcription').length > 0) {
                // Create the TextItems for the transcription and the MarkerItems for the audio/video player.
                try {
                    await this.getAndStoreTranscriptionAndDiarization(this.state.mediaId);
                    await this.handleOnExecuteSearch(this.state.searchQuery);
                }
                catch (error: any) {
                    log.error(`[Play, componentDidMount] Error during retrieval of transcription and diarization: ${error.message}`);
                }
            }

            // Retrieve the waveforms if available.
            let waveforms: number[][] = [];
            const waveformReferences = mediaInfo.contentReferences.filter((reference: any) => reference.type === 'waveform');
            for (const waveformReference of waveformReferences) {
                let waveformArray : number[] = [];

                try {
                    const waveformBlob = await this.props.dataContext.getMediaContent(this.state.mediaId, waveformReference.reference);
                    const waveformText = await waveformBlob.text();

                    waveformArray = waveformText.split(',').map((x: any) => (+x) / 1000);
                }
                catch (error: any) {
                    log.error(`[Play, componentDidMount] Error during retrieval of waveform: ${error.message}`)
                }

                if (waveformArray.length > 10) {
                    waveforms.push(waveformArray);
                }
            }

            // Get the URL to the actual content.
            const contentUrl = await this.props.dataContext.getMediaContentUrl(this.state.mediaId, contentReference, TOKEN_VALID_TIME_SECONDS);

            this.setState({
                contentUrl: contentUrl.url,
                subtitleUrl: subtitleUrl,
                subtitleLanguageCode: subtitleLanguageCode,
                waveforms: waveforms,
                contentType: contentType
            });
        }
        catch (error: any) {
            log.error(`[Play, componentDidMount] Error during retrieval of media info: ${error.message}`);
            this.setState({
                hasError: true,
                errorDescription: 'Error retrieving media'
            });

            return;
        }
    }

    componentWillUnmount() {
        this.context.addEvents([])
    }

    getAndStoreTranscriptionAndDiarization = async (mediaId: string) => {
        const mediaTranscription = await this.props.dataContext.getTranscription(mediaId);
        const diarization = await this.props.dataContext.getTextDiarization(mediaId);

        const transcriptionAndDiarization = {
            transcription: mediaTranscription,
            diarization: diarization
        }

        this.setState(transcriptionAndDiarization);

        // The state is updated asynchronously, but the just retrieved transcription and diarization are required by other code immediately.
        // For this reason these objects are also assigned to local fields.
        this.transcription = mediaTranscription;
        this.diarization = diarization;

        return transcriptionAndDiarization;
    }

    handleOnDownload =()=>{
        onDownload(this.state.contentUrl)
    }

    handleSetPositionFromSideBar = (position: number) => {
        this.setState({ 
            positionRequest: {
                position: position
            } 
        });
    }

    handleVideoOnTimeUpdate = (currentTime: number) => {
        this.setState({ offsetSeconds: currentTime });
    }

    handleSidebarWidthChangeTrigger = (sidebarWidth: number) => {
      this.setState({ sidebarWidthChangeTrigger: sidebarWidth})
    }

    handleOnExecuteSearch = async (searchQuery: string) => {
        if (!this.transcription || !this.diarization) {
            // Ignore the request to execute the search because no transcription or diarization is available.
            return;
        }
        
        let audioSearchHits: AudioSearchHits = {
            searchHits: []
        };
        if (searchQuery !== ""){
            audioSearchHits = await this.props.dataContext.getAudioSearchHits(this.state.mediaId, searchQuery);
            }

        const textItems = transcription.createTextItems(this.transcription, this.diarization, audioSearchHits);

        this.setState({            
            textItems: textItems,
        });

        const events = this.createTimelineEvents(textItems);
        this.context.addEvents(events);
    }

     createTimelineEvents = (textItems: TextItem[]) : TimelineEvent[] => {  
        let eventItems: TimelineEvent[] = [];
        for (let textItem of textItems) {
            if (textItem.highlights.length === 0) {
                continue;
            }
    
            const totalOffset = textItem.highlights.map(item => item.offsetSeconds).reduce((a, b) => (a + b));
            const averageOffset = totalOffset / textItem.highlights.length;
                    
            let eventItem: TimelineEvent = {
                offsetSeconds: textItem.offsetSeconds + averageOffset,
                getTooltipTitle: () => {
                    let participant = this.state.participants.find(item => item.participantId === textItem.participantId) || this.state.participants[0];
                    
                    return participant.displayname + " | " + durationToPlayerDisplayString(textItem.offsetSeconds);
                },

                // Note: An empty method is passed for the onClickHighlight handler because the highlights in the tooltip should not be clickable.
                getTooltipContent: () => {
                    return createContent(textItem, css.highlight, () => {});
                }
            }
    
            eventItems.push(eventItem);
        }
    
        return eventItems;
    }

    render() {
        const { contentType, subtitleUrl, subtitleLanguageCode, positionRequest, hasError, errorDescription } = this.state;
        
        let playerRendered: any = null;
        if (contentType === 'video') {
            playerRendered = <VideoPlayer
                contentUrl={this.state.contentUrl}
                subtitleUrl={subtitleUrl}
                code={subtitleLanguageCode}
                timelineEvents={this.context.events}
                positionRequest={positionRequest}                
                onTimeUpdate={this.handleVideoOnTimeUpdate}
                onDownload={this.handleOnDownload}                          
            />
        } else if (contentType === 'audio') {
            playerRendered = <AudioPlayer 
                contentUrl={this.state.contentUrl} 
                waveforms={this.state.waveforms}
                timelineEvents={this.context.events}
                positionRequest={positionRequest}                
                onTimeUpdate={this.handleVideoOnTimeUpdate}
                onDownload={this.handleOnDownload}
            />
        } else {
            playerRendered = <p>Loading media info...</p>
        }

        if (hasError) {
            playerRendered = <div className="error"><p>{errorDescription}</p></div>
        }
        return (
            <section className={mergeClasses(this.props.styles.theme.body ,'support-main-content')} style={{ height: '100vh', overflow: 'auto' }}>
                <section>
                    <PlayHeader 
                        mediaInfo={this.state.mediaInfo}
                        onDownload={this.handleOnDownload}
                    />   
                </section>
                <section className="video-content player-container">
                    <section className="side-bar-play" >
                        <div className="video-left-panel">
                            <PlaySideBar
                                mediaInfo={this.state.mediaInfo}
                                participants={this.state.participants}
                                textItems={this.state.textItems}
                                showItemAtOffsetSeconds={this.state.offsetSeconds}
                                onSetPositionRequest={this.handleSetPositionFromSideBar}
                                initialSearchQuery={this.state.searchQuery}
                                onExecuteSearch={this.handleOnExecuteSearch}
                                triggerParentRerender={this.handleSidebarWidthChangeTrigger}
                                onDownload={this.handleOnDownload}
                            />
                        </div>
                    </section>
                    <section className="main-content">
                        <div className={mergeClasses(this.props.styles.theme.container, 'panel')}>
                            <div className="panel-body">
                                <div className="support-video" id="support-video">
                                    {playerRendered}
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </section>
        );
    }
}
PlayInternal.contextType = TimelineContext;
  
const Play: React.FunctionComponent<PlayInternalProps> = (props: PlayInternalProps) => {
      const dataContext = useContext(DataContext);
      if (!dataContext) {
          throw new Error("No dataContext found in React tree.");
      }
  
      return (
          <PlayInternal {...props} dataContext={dataContext} />
      )
  }

  export default connectTeamsTheme(Play);