import React, { Component } from 'react';
import './SearchResultHeader.scss';
import { SearchContext } from '../../context/SearchContext';
import { 
  ArrowUp32Filled as ArrowUpIcon,
  ArrowDown32Filled as ArrowDownIcon,
} from '@fluentui/react-icons';
import { Button, Dropdown, Option } from '@fluentui/react-components';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";

class SearchResultsHeader extends Component {
    static contextType = SearchContext;

    getSortOrderIcon = () => {
        if (this.props.searchSettings.sortOrder === "asc") {
            return <ArrowUpIcon/>;
        } else {
            return <ArrowDownIcon/>;
        }
    }

    onSortOrderClick = () => {
        let sortOrder = "asc"
        if (this.props.searchSettings.sortOrder === "asc") {
            sortOrder = "desc"
        }

        const searchContext = this.context;
        searchContext.updateSorting(this.props.searchSettings.sortBy, sortOrder);
    }

    onSortByChange = (_, data) => {
        const searchContext = this.context;
        searchContext.updateSorting(data.optionValue, this.props.searchSettings.sortOrder);
    }


    render() {
        const sorterOptions = [];

        for (let i = 0; i < this.props.sorterConfig.length; i++) {
            const sorter = this.props.sorterConfig[i];

            sorterOptions.push({header: sorter.displayName, name: sorter.name });
        }

        const searchContext = this.context;
        const headerText = searchContext.isExecutingSearch ?
            'Searching...' :
            `${this.props.searchSettings.skip + 1}-${this.props.searchSettings.skip + this.props.searchResults.documentCount} of ${this.props.searchResults.totalCount} results`;
        return (
            <header className="wrapper-header display--flex flex--wrap">
                <p className="title">{headerText}</p>
                <div className="support-sorters-panel display--flex">
                    <p className="sorter-title">Sort by:</p>
                      <Dropdown
                        defaultValue={sorterOptions[1].header}
                        onOptionSelect={this.onSortByChange}
                      >
                      {sorterOptions.map(option => (
                        <Option value={option.name}>
                          {option.header}
                        </Option>
                      ))}
                      </Dropdown>
                      <Button
                        appearance="transparent"
                        className={this.props.styles.theme.button}
                        onClick={this.onSortOrderClick}
                        icon={this.getSortOrderIcon()}
                        title="Change sorting order"
                    />
                </div>
            </header>
        );
    }
}

const SearchResultHeader = connectTeamsTheme(SearchResultsHeader);
export default SearchResultHeader