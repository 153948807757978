import React, { createContext, ReactNode } from 'react';

/** Interface for events/items which will be added to the timeline. */
export interface TimelineEvent{
    offsetSeconds: number;
    /** Option: the duration of the marker. When specified the weight of the marker corresponds to the duration. */
    durationInSecond?:number
    /** the getTooltipTitle function is responsible for returning the title of the tooltip. A function is used so it's generated on-demand (when required) */
    getTooltipTitle(): string;
    /** the getTooltipContent function is responsible for returning the content of the tooltip. A function is used so it's generated on-demand (when required) */
    getTooltipContent(): ReactNode
}

interface TimelineContextProps{    
    events: TimelineEvent[];
}

interface TimelineContextFunctions{
    addEvents(items: TimelineEvent[]): void;
}

export interface TimelineContextInterface extends TimelineContextProps, TimelineContextFunctions {
    events: TimelineEvent[];
    addEvents(items: TimelineEvent[]): void;    
}

export const TimelineContext = createContext<TimelineContextInterface>({
    events: [],
    addEvents: (items: TimelineEvent[]) => {throw new Error('Should be implemented by TimelineContextProvider')}
});


interface TimelineContextProviderProps {
    children?: React.ReactNode,
}

interface TimelineContextProviderState extends TimelineContextProps  {
    
}

export class TimelineContextProvider extends React.Component<TimelineContextProviderProps, TimelineContextProviderState> {
    state = {
        events: [],
    }

    addEvents =  (items: TimelineEvent[]) => {
        this.setState({events: items});
    };

    render() {        
        return (
            <TimelineContext.Provider value={{
                events: this.state.events,
                addEvents: this.addEvents
            }}>
                {this.props.children}
            </TimelineContext.Provider>
        )
    }
}
