import { Component } from 'react'
import { Dropdown, Option } from '@fluentui/react-components'
import { Field } from '../../types/Field'

interface SearchInDropDownProps {
    fields: Field[];
    onChange?(e: SearchInDropDownChangedEventArgs): void;
}

interface SearchInDropDownState {
}

export interface SearchInDropDownChangedEventArgs {
    selectedItem: string;
}

const cacheActiveFilter = 'cacheActiveFilter'

function fieldDisplayNameComparer(field1: Field, field2: Field) {
    if (field1.userSettings.displayName < field2.userSettings.displayName) {
        return -1;
    }
    if (field1.userSettings.displayName > field2.userSettings.displayName) {
        return 1;
    }
    return 0;
}

function getInputItems(fields: Field[]): any[] {
    const fieldsToShow = fields.filter(f => f.systemSettings.isSearchable && f.userSettings.showInSearchInFieldsSelector).sort(fieldDisplayNameComparer);

    let items = fieldsToShow.map(f => {
        return {
            key: f.name,
            header: f.userSettings.displayName
        };
    });
    items.unshift({
        key: "#all#",
        header: "(All Fields)"
    });
    return items;
}

export default class SearchInDropDown extends Component<SearchInDropDownProps, SearchInDropDownState> {
    state = {}

    onChangeClick = (event: any, data: any) => {
        if (this.props.onChange) {
            let selectedItem = data.optionValue === "#all#" ? undefined : data.optionValue;
            this.props.onChange({ selectedItem: selectedItem })
        }
        localStorage.setItem(cacheActiveFilter, data.optionText)
    }

    render() {
        const renderActiveFilter = localStorage.getItem(cacheActiveFilter)
        const items = getInputItems(this.props.fields);
        return (
              <Dropdown
                onOptionSelect={this.onChangeClick}
                defaultValue={renderActiveFilter ?? items[0].header}
              >
              {items.map(item => (
                <Option value={item.key}>{item.header}</Option>
              ))}
            </Dropdown>
        )
    }
}